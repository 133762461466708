// Development specific configuration
// ==================================

//  staging, production : smartpharma360
// sandbox, uat : onemedcare

module.exports = {
  urls: {
    pillPlusService: 'https://www.smartpharma360.in',
  },
  analytics: {
    googleTrackingId: 'G-4P7SYTW0F1',
  },
};
