/* eslint-disable global-require */

// The top-level (parent) route
const routes = {
  path: '',
  defaultPath: {
    path: '/login',
    load: () => import(/* webpackChunkName: 'login_page' */ './login'),
  },
  // Keep in mind, routes are evaluated in order
  children: [
    {
      path: '',
      load: () => import(/* webpackChunkName: 'home' */ './home'),
    },
    {
      authenticate: false,
      path: '/login',
      load: () => import(/* webpackChunkName: 'login' */ './login'),
    },
    {
      authenticate: false,
      path: '/privacy',
      load: () => import(/* webpackChunkName: 'privacy' */ './privacy'),
    },
    {
      authenticate: false,
      path: '/he-privacy',
      load: () => import(/* webpackChunkName: 'privacy' */ './privacy-he'),
    },
    {
      path: '/changelog',
      load: () => import(/* webpackChunkName: 'changelog' */ './changelog'),
    },
    {
      path: '/profile',
      load: () => import(/* webpackChunkName: 'profile' */ './profile'),
    },
    {
      path: '/home',
      load: () => import(/* webpackChunkName: 'home' */ './home'),
    },
    {
      path: '/dashboard',
      load: () => import(/* webpackChunkName: 'home' */ './dashboard'),
    },
    {
      path: '/inward_order_home',
      load: () =>
        import(
          /* webpackChunkName: 'inward_order_home' */ './inventory_management/inward_order/inward_order_home.js'
        ),
    },
    {
      path: '/inward_order/inward_order_entry',
      load: () =>
        import(
          /* webpackChunkName: 'inward_entry_new' */ './inventory_management/inward_order/inward_order_entry.js'
        ),
    },
    {
      path: '/inward_order/inward_order_details',
      load: () =>
        import(
          /* webpackChunkName: 'inward_details' */ './inventory_management/inward_order/inward_order_details.js'
        ),
    },
    {
      path: '/inward_order/inward_order_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'inward_details_edit' */ './inventory_management/inward_order/inward_order_details_edit.js'
        ),
    },
    {
      path: '/retail/sales',
      load: () =>
        import(
          /* webpackChunkName: 'retail_sale_home' */ './inventory_management_retail/sale/sale_home'
        ),
    },
    {
      path: '/retail/sales/sale_entry',
      load: () =>
        import(
          /* webpackChunkName: 'retail_sale_entry' */ './inventory_management_retail/sale/sale_entry'
        ),
    },
    {
      path: '/retail/sales/sale_details',
      load: () =>
        import(
          /* webpackChunkName: 'retail_sale_details' */ './inventory_management_retail/sale/sale_details'
        ),
    },
    {
      path: '/retail/sales/sale_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'retail_sale_details_edit' */ './inventory_management_retail/sale/sale_details_edit'
        ),
    },
    {
      path: '/retail/direct_sales',
      load: () =>
        import(
          /* webpackChunkName: 'retail_direct_sale_home' */ './inventory_management_retail/direct_sale/sale_home'
        ),
    },
    {
      path: '/retail/direct_sales/sale_entry',
      load: () =>
        import(
          /* webpackChunkName: 'retail_direct_sale_entry' */ './inventory_management_retail/direct_sale/sale_entry'
        ),
    },
    {
      path: '/retail/direct_sales/sale_details',
      load: () =>
        import(
          /* webpackChunkName: 'retail_direct_sale_details' */ './inventory_management_retail/direct_sale/sale_details'
        ),
    },
    {
      path: '/retail/direct_sales/sale_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'retail_direct_sale_details_edit' */ './inventory_management_retail/direct_sale/sale_details_edit'
        ),
    },
    {
      path: '/retail/opening_stock',
      load: () =>
        import(
          /* webpackChunkName: 'opening_stock_home' */ './inventory_management_retail/opening_stock/opening_stock_home'
        ),
    },
    {
      path: '/retail/opening_stock_entry',
      load: () =>
        import(
          /* webpackChunkName: 'opening_stock_entry' */ './inventory_management_retail/opening_stock/opening_stock_entry'
        ),
    },
    {
      path: '/retail/opening_stock_details',
      load: () =>
        import(
          /* webpackChunkName: 'opening_stock_details' */ './inventory_management_retail/opening_stock/opening_stock_details'
        ),
    },
    {
      path: '/retail/opening_stock_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'opening_stock_edit' */ './inventory_management_retail/opening_stock/opening_stock_details_edit'
        ),
    },
    {
      path: '/retail/sales_return',
      load: () =>
        import(
          /* webpackChunkName: 'retail_sale_return' */ './inventory_management_retail/sale_return/sale_return_home.js'
        ),
    },
    {
      path: '/retail/sale_return_entry',
      load: () =>
        import(
          /* webpackChunkName: 'retail_sale_return_entry' */ './inventory_management_retail/sale_return/sale_return_entry.js'
        ),
    },
    {
      path: '/retail/sale_return_details',
      load: () =>
        import(
          /* webpackChunkName: 'retail_sale_return_details' */ './inventory_management_retail/sale_return/sale_return_details.js'
        ),
    },
    {
      path: '/retail/sale_return_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'retail_sale_return_edit' */ './inventory_management_retail/sale_return/sale_return_details_edit.js'
        ),
    },
    {
      path: '/sale_return_retail',
      load: () =>
        import(
          /* webpackChunkName: 'sale_return_retail' */ './inventory_management/sale_return_retail/sale_return_home'
        ),
    },
    {
      path: '/sale_return_retail/sale_return_retail_entry',
      load: () =>
        import(
          /* webpackChunkName: 'sale_return_retail_entry' */ './inventory_management/sale_return_retail/sale_return_entry'
        ),
    },
    {
      path: '/sale_return_retail/sale_return_retail_details',
      load: () =>
        import(
          /* webpackChunkName: 'sale_return_retail_details' */ './inventory_management/sale_return_retail/sale_return_details'
        ),
    },
    {
      path: '/sale_return_retail/sale_return_retail_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'sale_return_retail_details_edit' */ './inventory_management/sale_return_retail/sale_return_details_edit'
        ),
    },

    // Purchase Order Home
    {
      path: '/purchase_order',
      load: () =>
        import(
          /* webpackChunkName: 'purchase_order' */ './order_management/purchase_order/purchase_order_home.js'
        ),
    },

    // Purchase Order Entry
    {
      path: '/purchase_order/purchase_order_entry',
      load: () =>
        import(
          /* webpackChunkName: 'purchase_order_entry' */ './order_management/purchase_order/purchase_order_entry.js'
        ),
    },

    // Purchase Order Details
    {
      path: '/purchase_order/purchase_order_details',
      load: () =>
        import(
          /* webpackChunkName: 'purchase_order_details' */ './order_management/purchase_order/purchase_order_details.js'
        ),
    },

    // Purchase Order Admin Edit
    {
      path: '/purchase_order/purchase_order_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'purchase_order_details_edit' */ './order_management/purchase_order/purchase_order_details_edit.js'
        ),
    },

    // Sale Based Purchase Order (Multiple Supplier) Home
    {
      path: '/purchase_order/purchase_order_multi_supplier',
      load: () =>
        import(
          /* webpackChunkName: 'purchase_order_multi_supplier_entry' */ './order_management/purchase_order/purchase_order_multi_supplier_home.js'
        ),
    },

    // Sale Based Purchase Order (Multiple Supplier) Entry
    {
      path: '/purchase_order/purchase_order_multi_supplier_entry',
      load: () =>
        import(
          /* webpackChunkName: 'purchase_order_multi_supplier_entry' */ './order_management/purchase_order/purchase_order_multi_supplier_entry.js'
        ),
    },

    // Sale Based Purchase Order (Multiple Supplier) Details
    {
      path: '/purchase_order/purchase_order_multi_supplier_details',
      load: () =>
        import(
          /* webpackChunkName: 'purchase_order_multi_supplier_entry' */ './order_management/purchase_order/purchase_order_multi_supplier_details.js'
        ),
    },

    // Sale Based Purchase Order (Multiple Supplier) Admin Edit
    {
      path: '/purchase_order/purchase_order_multi_supplier_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'purchase_order_multi_supplier_details_edit' */ './order_management/purchase_order/purchase_order_multi_supplier_details_edit.js'
        ),
    },

    // Purchase Order (Manual) Entry
    {
      path: '/manual_purchase_order/purchase_order_entry',
      load: () =>
        import(
          /* webpackChunkName: 'manual_purchase_order_entry' */ './order_management/purchase_order/manual_purchase_order_entry.js'
        ),
    },
    // Purchase Order (Manual) Admin Edit
    {
      path: '/manual_purchase_order/purchase_order_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'manual_purchase_order_edit' */ './order_management/purchase_order/manual_purchase_order_details_edit.js'
        ),
    },

    {
      path: '/retail/purchase_order',
      load: () =>
        import(
          /* webpackChunkName: 'retail_purchase_order' */ './order_management/purchase_order/purchase_order_home.js'
        ),
    },
    {
      path: '/stock_audit_home',
      load: () =>
        import(
          /* webpackChunkName: 'stock_audit_home' */ './inventory_management/stock_audit/stock_audit_home'
        ),
    },
    {
      path: '/stock_audit',
      load: () => import(/* webpackChunkName: 'stock_audit' */ './stock_audit'),
    },
    {
      path: '/stock_audit_details',
      load: () =>
        import(
          /* webpackChunkName: 'stock_audit_details' */ './stock_audit_details'
        ),
    },
    {
      path: '/manufacturing',
      load: () =>
        import(
          /* webpackChunkName: 'manufacturing_home' */ './tools/manufacturing/home.js'
        ),
    },
    {
      path: '/manufacturing/entry',
      load: () =>
        import(
          /* webpackChunkName: 'manufacturing_entry' */ './tools/manufacturing/entry.js'
        ),
    },
    {
      path: '/manufacturing/details',
      load: () =>
        import(
          /* webpackChunkName: 'manufacturing_details' */ './tools/manufacturing/details.js'
        ),
    },
    {
      path: '/manufacturing/details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'manufacturing_details_admin_edit' */ './tools/manufacturing/details_edit.js'
        ),
    },

    {
      path: '/contract_pricing/customer',
      load: () =>
        import(
          /* webpackChunkName: 'cp_home' */ './contract_pricing/customer/cp_home'
        ),
    },
    {
      path: '/contract_pricing/customer/entry',
      load: () =>
        import(
          /* webpackChunkName: 'cp_entry' */ './contract_pricing/customer/cp_entry'
        ),
    },
    {
      path: '/contract_pricing/customer/details',
      load: () =>
        import(
          /* webpackChunkName: 'cp_details' */ './contract_pricing/customer/cp_details'
        ),
    },
    {
      path: '/contract_pricing/customer/edit',
      load: () =>
        import(
          /* webpackChunkName: 'cp_details_edit' */ './contract_pricing/customer/cp_details_edit'
        ),
    },

    /////////////////////////////////////////////// inventory_management => OUTWARD ///////////////////////////////////////////////
    {
      path: '/sales',
      load: () =>
        import(
          /* webpackChunkName: 'sale_home' */ './inventory_management/sale/sale_home.js'
        ),
    },
    {
      path: '/sales/sale_entry',
      load: () =>
        import(
          /* webpackChunkName: 'sale_entry' */ './inventory_management/sale/sale_entry.js'
        ),
    },
    {
      path: '/sales/sale_details',
      load: () =>
        import(
          /* webpackChunkName: 'sale_details' */ './inventory_management/sale/sale_details.js'
        ),
    },
    {
      path: '/sales/sale_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'sale_details_edit' */ './inventory_management/sale/sale_details_edit.js'
        ),
    },
    {
      path: '/sales_return',
      load: () =>
        import(
          /* webpackChunkName: 'sale_return_home' */ './inventory_management/sale_return/sale_return_home.js'
        ),
    },
    {
      path: '/sales/sale_return_entry',
      load: () =>
        import(
          /* webpackChunkName: 'sale_return' */ './inventory_management/sale_return/sale_return_entry.js'
        ),
    },
    {
      path: '/sales/sale_return_details',
      load: () =>
        import(
          /* webpackChunkName: 'sale_return_details' */ './inventory_management/sale_return/sale_return_details.js'
        ),
    },
    {
      path: '/sales/sale_return_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'sale_return_details_edit' */ './inventory_management/sale_return/sale_return_details_edit.js'
        ),
    },
    {
      path: '/sales_dc',
      load: () =>
        import(
          /* webpackChunkName: 'sale_dc_home' */ './inventory_management/sale_dc/sale_dc_home.js'
        ),
    },
    {
      path: '/sales_dc/sale_dc_entry',
      load: () =>
        import(
          /* webpackChunkName: 'sale_dc' */ './inventory_management/sale_dc/sale_dc_entry.js'
        ),
    },
    {
      path: '/sales_dc/sale_dc_details',
      load: () =>
        import(
          /* webpackChunkName: 'sale_dc_details' */ './inventory_management/sale_dc/sale_dc_details.js'
        ),
    },
    {
      path: '/sales_dc/sale_dc_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'sale_dc_details_edit' */ './inventory_management/sale_dc/sale_dc_details_edit.js'
        ),
    },
    {
      path: '/sale_retail',
      load: () =>
        import(
          /* webpackChunkName: 'retail_sale_home' */ './inventory_management/sale_retail/sale_retail_home.js'
        ),
    },
    {
      path: '/sale_retail/sale_retail_entry',
      load: () =>
        import(
          /* webpackChunkName: 'retail_sale_entry' */ './inventory_management/sale_retail/sale_retail_entry.js'
        ),
    },
    {
      path: '/sale_retail/sale_retail_details',
      load: () =>
        import(
          /* webpackChunkName: 'retail_sale_details' */ './inventory_management/sale_retail/sale_retail_details.js'
        ),
    },
    {
      path: '/sale_retail/sale_retail_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'retail_sale_details' */ './inventory_management/sale_retail/sale_retail_details_edit.js'
        ),
    },
    {
      path: '/purchase_return',
      load: () =>
        import(
          /* webpackChunkName: 'purchase_return_home' */ './inventory_management/purchase_return/purchase_return_home.js'
        ),
    },
    {
      path: '/purchase/purchase_return_entry',
      load: () =>
        import(
          /* webpackChunkName: 'purchase_return_entry' */ './inventory_management/purchase_return/purchase_return_entry.js'
        ),
    },
    {
      path: '/purchase/purchase_return_details',
      load: () =>
        import(
          /* webpackChunkName: 'purchase_return_details' */ './inventory_management/purchase_return/purchase_return_details.js'
        ),
    },
    {
      path: '/purchase/purchase_return_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'purchase_return_details_edit' */ './inventory_management/purchase_return/purchase_return_details_edit.js'
        ),
    },
    {
      path: '/transfer_out',
      load: () =>
        import(
          /* webpackChunkName: 'transfer_out_home' */ './inventory_management/transfer_out/transfer_out_home.js'
        ),
    },
    {
      path: '/transfer_out/transfer_out_entry',
      load: () =>
        import(
          /* webpackChunkName: 'transfer_out' */ './inventory_management/transfer_out/transfer_out_entry.js'
        ),
    },
    {
      path: '/transfer_out/transfer_out_details',
      load: () =>
        import(
          /* webpackChunkName: 'transfer_out_details' */ './inventory_management/transfer_out/transfer_out_details.js'
        ),
    },
    {
      path: '/transfer_out/transfer_out_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'transfer_out_details_edit' */ './inventory_management/transfer_out/transfer_out_details_edit.js'
        ),
    },
    {
      path: '/proforma',
      load: () =>
        import(
          /* webpackChunkName: 'proforma_home' */ './inventory_management/proforma/proforma_home.js'
        ),
    },
    {
      path: '/proforma/proforma_entry',
      load: () =>
        import(
          /* webpackChunkName: 'proforma_entry' */ './inventory_management/proforma/proforma_entry.js'
        ),
    },
    {
      path: '/proforma/proforma_details',
      load: () =>
        import(
          /* webpackChunkName: 'proforma_details' */ './inventory_management/proforma/proforma_details.js'
        ),
    },
    {
      path: '/special_sales',
      load: () =>
        import(
          /* webpackChunkName: 'special_sales' */ './inventory_management/special_sale/sale_home.js'
        ),
    },
    {
      path: '/special_sales/sale_entry',
      load: () =>
        import(
          /* webpackChunkName: 'special_sale_entry' */ './inventory_management/special_sale/sale_entry.js'
        ),
    },
    {
      path: '/special_sales/sale_details',
      load: () =>
        import(
          /* webpackChunkName: 'special_sale_details' */ './inventory_management/special_sale/sale_details.js'
        ),
    },
    {
      path: '/special_sales/sale_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'special_sale_details_edit' */ './inventory_management/special_sale/sale_details_edit.js'
        ),
    },
    {
      path: '/expiry_out',
      load: () =>
        import(
          /* webpackChunkName: 'expiry_out_home' */ './inventory_management/expiry_out/expiry_out_home.js'
        ),
    },
    {
      path: '/expiry_out/expiry_out_entry',
      load: () =>
        import(
          /* webpackChunkName: 'expiry_out_entry' */ './inventory_management/expiry_out/expiry_out_entry.js'
        ),
    },
    {
      path: '/expiry_out/expiry_out_details',
      load: () =>
        import(
          /* webpackChunkName: 'expiry_out_details' */ './inventory_management/expiry_out/expiry_out_details.js'
        ),
    },
    {
      path: '/expiry_out/expiry_out_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'expiry_out_details_edit' */ './inventory_management/expiry_out/expiry_out_details_edit.js'
        ),
    },
    /////////////////////////////////////////////// inventory_management => INWARD ///////////////////////////////////////////////
    {
      path: '/purchase',
      load: () =>
        import(
          /* webpackChunkName: 'purchase_home' */ './inventory_management/purchase/purchase_home.js'
        ),
    },
    {
      path: '/purchase/purchase_entry',
      load: () =>
        import(
          /* webpackChunkName: 'purchase_entry' */ './inventory_management/purchase/purchase_entry.js'
        ),
    },
    {
      path: '/purchase/purchase_details',
      load: () =>
        import(
          /* webpackChunkName: 'purchase_details' */ './inventory_management/purchase/purchase_details.js'
        ),
    },
    {
      path: '/purchase/purchase_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'purchase_details_edit' */ './inventory_management/purchase/purchase_details_edit.js'
        ),
    },
    {
      path: '/purchase_dc',
      load: () =>
        import(
          /* webpackChunkName: 'purchase_dc_home' */ './inventory_management/purchase_dc/purchase_dc_home.js'
        ),
    },
    {
      path: '/purchase/purchase_dc_entry',
      load: () =>
        import(
          /* webpackChunkName: 'purchase_dc' */ './inventory_management/purchase_dc/purchase_dc_entry.js'
        ),
    },
    {
      path: '/purchase/purchase_dc_details',
      load: () =>
        import(
          /* webpackChunkName: 'purchase_dc_details' */ './inventory_management/purchase_dc/purchase_dc_details.js'
        ),
    },
    {
      path: '/purchase/purchase_dc_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'purchase_dc_details_edit' */ './inventory_management/purchase_dc/purchase_dc_details_edit.js'
        ),
    },
    {
      path: '/transfer_in',
      load: () =>
        import(
          /* webpackChunkName: 'transfer_in_home' */ './inventory_management/transfer_in/transfer_in_home.js'
        ),
    },
    {
      path: '/transfer_in/transfer_in_entry',
      load: () =>
        import(
          /* webpackChunkName: 'transfer_in' */ './inventory_management/transfer_in/transfer_in_entry.js'
        ),
    },
    {
      path: '/transfer_in/transfer_in_details',
      load: () =>
        import(
          /* webpackChunkName: 'transfer_in_details' */ './inventory_management/transfer_in/transfer_in_details.js'
        ),
    },
    {
      path: '/transfer_in/transfer_in_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'transfer_in_details_edit' */ './inventory_management/transfer_in/transfer_in_details_edit.js'
        ),
    },
    {
      path: '/special_purchases',
      load: () =>
        import(
          /* webpackChunkName: 'special_purchase_home' */ './inventory_management/special_purchase/purchase_home.js'
        ),
    },
    {
      path: '/special_purchase/purchase_entry',
      load: () =>
        import(
          /* webpackChunkName: 'special_purchase_entry' */ './inventory_management/special_purchase/purchase_entry.js'
        ),
    },
    {
      path: '/special_purchase/purchase_details',
      load: () =>
        import(
          /* webpackChunkName: 'special_purchase_details' */ './inventory_management/special_purchase/purchase_details.js'
        ),
    },
    {
      path: '/special_purchase/purchase_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'special_purchase_details_edit' */ './inventory_management/special_purchase/purchase_details_edit.js'
        ),
    },
    {
      path: '/expiry_in',
      load: () =>
        import(
          /* webpackChunkName: 'expiry_in_home' */ './inventory_management/expiry_in/expiry_in_home.js'
        ),
    },
    {
      path: '/expiry_in/expiry_in_entry',
      load: () =>
        import(
          /* webpackChunkName: 'expiry_in_entry' */ './inventory_management/expiry_in/expiry_in_entry.js'
        ),
    },
    {
      path: '/expiry_in/expiry_in_details',
      load: () =>
        import(
          /* webpackChunkName: 'expiry_in_details' */ './inventory_management/expiry_in/expiry_in_details.js'
        ),
    },
    {
      path: '/expiry_in/expiry_in_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'expiry_in_details_edit' */ './inventory_management/expiry_in/expiry_in_details_edit.js'
        ),
    },
    {
      path: '/excess_replacements',
      load: () =>
        import(
          /* webpackChunkName: 'excess_replacements' */ './inventory_management/excess_replacement/excess_replacement_home.js'
        ),
    },
    {
      path: '/excess_replacements/entry',
      load: () =>
        import(
          /* webpackChunkName: 'excess_replacements_entry' */ './inventory_management/excess_replacement/excess_replacement_entry.js'
        ),
    },
    {
      path: '/excess_replacements/details',
      load: () =>
        import(
          /* webpackChunkName: 'excess_replacements_details' */ './inventory_management/excess_replacement/excess_replacement_details.js'
        ),
    },
    {
      path: '/excess_replacements/details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'excess_replacements_details_edit' */ './inventory_management/excess_replacement/excess_replacement_details_edit.js'
        ),
    },
    {
      path: '/excess_schemes',
      load: () =>
        import(
          /* webpackChunkName: 'excess_schemes' */ './inventory_management/excess_schemes/home.js'
        ),
    },
    {
      path: '/excess_schemes/excess_scheme_entry',
      load: () =>
        import(
          /* webpackChunkName: 'excess_scheme_entry' */ './inventory_management/excess_schemes/entry.js'
        ),
    },
    {
      path: '/excess_schemes/excess_scheme_details',
      load: () =>
        import(
          /* webpackChunkName: 'excess_scheme_details' */ './inventory_management/excess_schemes/details.js'
        ),
    },
    {
      path: '/excess_schemes/excess_scheme_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'excess_scheme_details_edit' */ './inventory_management/excess_schemes/details_edit.js'
        ),
    },
    {
      path: '/under_rate_billing',
      load: () =>
        import(
          /* webpackChunkName: 'under_rate_billing' */ './inventory_management/under_rate_billing/home.js'
        ),
    },
    {
      path: '/under_rate_billing/under_rate_billing_entry',
      load: () =>
        import(
          /* webpackChunkName: 'under_rate_billing_entry' */ './inventory_management/under_rate_billing/entry.js'
        ),
    },
    {
      path: '/under_rate_billing/under_rate_billing_details',
      load: () =>
        import(
          /* webpackChunkName: 'under_rate_billing_details' */ './inventory_management/under_rate_billing/details.js'
        ),
    },
    {
      path: '/sample_issue',
      load: () =>
        import(
          /* webpackChunkName: 'sample_issue' */ './inventory_management/sample_issue/home.js'
        ),
    },
    {
      path: '/sample_issue/entry',
      load: () =>
        import(
          /* webpackChunkName: 'sample_issue_entry' */ './inventory_management/sample_issue/entry.js'
        ),
    },
    {
      path: '/sample_issue/details',
      load: () =>
        import(
          /* webpackChunkName: 'sample_issue_details' */ './inventory_management/sample_issue/details.js'
        ),
    },
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    {
      path: '/retail/purchase',
      load: () =>
        import(
          /* webpackChunkName: 'retail_purchase_home' */ './inventory_management_retail/purchase/purchase_home'
        ),
    },
    {
      path: '/retail/retail_purchase_entry',
      load: () =>
        import(
          /* webpackChunkName: 'retail_purchase_entry' */ './inventory_management_retail/purchase/purchase_entry'
        ),
    },
    {
      path: '/retail/retail_purchase_details',
      load: () =>
        import(
          /* webpackChunkName: 'retail_purchase_details' */ './inventory_management_retail/purchase/purchase_details'
        ),
    },
    {
      path: '/retail/purchase/purchase_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'retail_purchase_details' */ './inventory_management_retail/purchase/purchase_details_edit'
        ),
    },
    {
      path: '/retail/purchase_dc',
      load: () =>
        import(
          /* webpackChunkName: 'retail_purchase_dc_home' */ './inventory_management_retail/purchase_dc/purchase_home'
        ),
    },
    {
      path: '/retail/purchase_dc_entry',
      load: () =>
        import(
          /* webpackChunkName: 'retail_purchase_dc_entry' */ './inventory_management_retail/purchase_dc/purchase_entry'
        ),
    },
    {
      path: '/retail/purchase_dc_details',
      load: () =>
        import(
          /* webpackChunkName: 'retail_purchase_dc_details' */ './inventory_management_retail/purchase_dc/purchase_details'
        ),
    },
    {
      path: '/retail/purchase_dc_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'retail_purchase_dc_details_edit' */ './inventory_management_retail/purchase_dc/purchase_details_edit'
        ),
    },
    {
      path: '/retail/stock_audit',
      load: () => import(/* webpackChunkName: 'stock_audit' */ './stock_audit'),
    },
    {
      path: '/retail/purchase_return',
      load: () =>
        import(
          /* webpackChunkName: 'retail_purchase_return_home' */ './inventory_management_retail/purchase_return/purchase_return_home'
        ),
    },
    {
      path: '/retail/purchase_return_entry',
      load: () =>
        import(
          /* webpackChunkName: 'retail_purchase_return' */ './inventory_management_retail/purchase_return/purchase_return_entry'
        ),
    },
    {
      path: '/retail/purchase_return_details',
      load: () =>
        import(
          /* webpackChunkName: 'retail_purchase_return_details' */ './inventory_management_retail/purchase_return/purchase_return_details'
        ),
    },
    {
      path: '/retail/purchase_return_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'retail_purchase_return_edit' */ './inventory_management_retail/purchase_return/purchase_return_details_edit'
        ),
    },
    {
      path: '/retail/transfer_in',
      load: () =>
        import(
          /* webpackChunkName: 'retail_transfer_in_home' */ './inventory_management_retail/transfer_in/transfer_in_home.js'
        ),
    },
    {
      path: '/retail/transfer_in/transfer_in_entry',
      load: () =>
        import(
          /* webpackChunkName: 'retail_transfer_in' */ './inventory_management_retail/transfer_in/transfer_in_entry.js'
        ),
    },
    {
      path: '/retail/transfer_in/transfer_in_details',
      load: () =>
        import(
          /* webpackChunkName: 'retail_transfer_in_details' */ './inventory_management_retail/transfer_in/transfer_in_details.js'
        ),
    },
    {
      path: '/retail/transfer_in/transfer_in_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'retail_transfer_in_details_edit' */ './inventory_management_retail/transfer_in/transfer_in_details_edit.js'
        ),
    },
    {
      path: '/retail/transfer_out',
      load: () =>
        import(
          /* webpackChunkName: 'retail_transfer_out_home' */ './inventory_management_retail/transfer_out/transfer_out_home.js'
        ),
    },
    {
      path: '/retail/transfer_out/transfer_out_entry',
      load: () =>
        import(
          /* webpackChunkName: 'retail_transfer_out' */ './inventory_management_retail/transfer_out/transfer_out_entry.js'
        ),
    },
    {
      path: '/retail/transfer_out/transfer_out_details',
      load: () =>
        import(
          /* webpackChunkName: 'retail_transfer_out_details' */ './inventory_management_retail/transfer_out/transfer_out_details.js'
        ),
    },
    {
      path: '/retail/transfer_out/transfer_out_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'retail_transfer_out_details_edit' */ './inventory_management_retail/transfer_out/transfer_out_details_edit.js'
        ),
    },
    {
      path: '/retail/proforma',
      load: () =>
        import(
          /* webpackChunkName: 'retail_proforma_home' */ './inventory_management_retail/proforma/proforma_home.js'
        ),
    },
    {
      path: '/retail/proforma/proforma_entry',
      load: () =>
        import(
          /* webpackChunkName: 'retail_proforma_entry' */ './inventory_management_retail/proforma/proforma_entry.js'
        ),
    },
    {
      path: '/b2b_sales',
      load: () =>
        import(
          /* webpackChunkName: 'b2b_sales_home' */ './inventory_management_retail/b2b_sale/b2b_sale_home.js'
        ),
    },
    {
      path: '/b2b_sales/sale_entry',
      load: () =>
        import(
          /* webpackChunkName: 'b2b_sales_entry' */ './inventory_management_retail/b2b_sale/b2b_sale_entry.js'
        ),
    },
    {
      path: '/b2b_sales/sale_details',
      load: () =>
        import(
          /* webpackChunkName: 'b2b_sales_details' */ './inventory_management_retail/b2b_sale/b2b_sale_details.js'
        ),
    },
    {
      path: '/b2b_sales/sale_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'b2b_sales_details_edit' */ './inventory_management_retail/b2b_sale/b2b_sale_details_edit.js'
        ),
    },
    {
      path: '/b2b_sales_return',
      load: () =>
        import(
          /* webpackChunkName: 'b2b_sale_return_home' */ './inventory_management_retail/b2b_sale_return/sale_return_home.js'
        ),
    },
    {
      path: '/b2b_sales_return/sale_return_entry',
      load: () =>
        import(
          /* webpackChunkName: 'b2b_sale_return_entry' */ './inventory_management_retail/b2b_sale_return/sale_return_entry.js'
        ),
    },
    {
      path: '/b2b_sales_return/sale_return_details',
      load: () =>
        import(
          /* webpackChunkName: 'b2b_sale_return_details' */ './inventory_management_retail/b2b_sale_return/sale_return_details.js'
        ),
    },
    {
      path: '/b2b_sales_return/sale_return_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'b2b_sale_return_details_edit' */ './inventory_management_retail/b2b_sale_return/sale_return_details_edit.js'
        ),
    },
    /////////////////////////////////////////////// masters_management /////////////////////////////////////////////////////////
    {
      path: '/products',
      load: () =>
        import(
          /* webpackChunkName: 'masters' */ './masters_management/products'
        ),
    },
    {
      path: '/company',
      load: () =>
        import(
          /* webpackChunkName: 'masters' */ './masters_management/company'
        ),
    },
    {
      path: '/division',
      load: () =>
        import(
          /* webpackChunkName: 'masters' */ './masters_management/division'
        ),
    },
    {
      path: '/hsn',
      load: () =>
        import(/* webpackChunkName: 'masters' */ './masters_management/hsn'),
    },
    {
      path: '/customers',
      load: () =>
        import(
          /* webpackChunkName: 'masters' */ './masters_management/customers'
        ),
    },
    {
      path: '/suppliers',
      load: () =>
        import(
          /* webpackChunkName: 'masters' */ './masters_management/suppliers'
        ),
    },
    {
      path: '/warehouses',
      load: () =>
        import(
          /* webpackChunkName: 'masters' */ './masters_management/warehouses'
        ),
    },
    {
      path: '/account_groups',
      load: () =>
        import(
          /* webpackChunkName: 'masters' */ './masters_management/account_groups'
        ),
    },
    {
      path: '/ledgers',
      load: () =>
        import(
          /* webpackChunkName: 'masters' */ './masters_management/ledgers'
        ),
    },
    {
      path: '/doctors',
      load: () =>
        import(
          /* webpackChunkName: 'masters' */ './masters_management/doctors'
        ),
    },
    {
      path: '/patients',
      load: () =>
        import(
          /* webpackChunkName: 'masters' */ './masters_management/patients'
        ),
    },
    {
      path: '/agents',
      load: () =>
        import(/* webpackChunkName: 'masters' */ './masters_management/agents'),
    },
    {
      path: '/reps',
      load: () =>
        import(/* webpackChunkName: 'masters' */ './masters_management/reps'),
    },
    {
      path: '/company_sales_team',
      load: () =>
        import(
          /* webpackChunkName: 'masters' */ './masters_management/company_sales_team'
        ),
    },
    {
      path: '/areas',
      load: () =>
        import(/* webpackChunkName: 'masters' */ './masters_management/areas'),
    },
    {
      path: '/disease_category',
      load: () =>
        import(
          /* webpackChunkName: 'masters' */ './masters_management/disease_category'
        ),
    },
    {
      path: '/discounts',
      load: () =>
        import(
          /* webpackChunkName: 'discounts' */ './masters_management/discounts'
        ),
    },
    {
      path: '/transport',
      load: () =>
        import(
          /* webpackChunkName: 'masters' */ './masters_management/transports'
        ),
    },
    {
      path: '/counter',
      load: () =>
        import(
          /* webpackChunkName: 'masters' */ './masters_management/counters'
        ),
    },
    {
      path: '/pos_machines',
      load: () =>
        import(
          /* webpackChunkName: 'masters' */ './masters_management/pos_machines'
        ),
    },
    {
      path: '/employees',
      load: () =>
        import(
          /* webpackChunkName: 'masters' */ './masters_management/employees'
        ),
    },
    {
      path: '/routes',
      load: () =>
        import(/* webpackChunkName: 'masters' */ './masters_management/route'),
    },
    {
      path: '/regions',
      load: () =>
        import(/* webpackChunkName: 'masters' */ './masters_management/region'),
    },
    {
      path: '/districts',
      load: () =>
        import(
          /* webpackChunkName: 'masters' */ './masters_management/district'
        ),
    },
    {
      path: '/fixed_asset',
      load: () =>
        import(
          /* webpackChunkName: 'masters' */ './masters_management/fixed_asset'
        ),
    },
    {
      path: '/products_non_tradable',
      load: () =>
        import(
          /* webpackChunkName: 'masters' */ './masters_management/products_non_tradable'
        ),
    },
    {
      path: '/cost_categories',
      load: () =>
        import(
          /* webpackChunkName: 'masters' */ './masters_management/cost_categories'
        ),
    },
    {
      path: '/cost_centers',
      load: () =>
        import(
          /* webpackChunkName: 'masters' */ './masters_management/cost_centers'
        ),
    },
    {
      path: '/predefined_cost_centers',
      load: () =>
        import(
          /* webpackChunkName: 'masters' */ './masters_management/predefined_cost_centers'
        ),
    },
    {
      path: '/product_type',
      load: () =>
        import(
          /* webpackChunkName: 'masters' */ './masters_management/product_type'
        ),
    },
    {
      path: '/procedures',
      load: () =>
        import(
          /* webpackChunkName: 'masters' */ './masters_management/procedures'
        ),
    },
    {
      path: '/states',
      load: () =>
        import(/* webpackChunkName: 'masters' */ './masters_management/states'),
    },
    {
      path: '/discount_locking',
      load: () =>
        import(
          /* webpackChunkName: 'masters' */ './masters_management/discount_locking'
        ),
    },
    {
      path: '/scheme_locking',
      load: () =>
        import(
          /* webpackChunkName: 'masters' */ './masters_management/scheme_locking'
        ),
    },
    {
      path: '/storage_cases',
      load: () =>
        import(
          /* webpackChunkName: 'masters' */ './masters_management/storage_cases'
        ),
    },
    {
      path: '/gelpack',
      load: () =>
        import(
          /* webpackChunkName: 'masters' */ './masters_management/gelpack'
        ),
    },
    {
      path: '/narrations',
      load: () =>
        import(
          /* webpackChunkName: 'masters' */ './masters_management/narrations'
        ),
    },
    {
      path: '/insurance',
      load: () =>
        import(
          /* webpackChunkName: 'masters' */ './masters_management/insurance'
        ),
    },

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      path: '/product_locations',
      load: () => import(/* webpackChunkName: 'batch' */ './product_locations'),
    },
    {
      path: '/batch_details',
      load: () =>
        import(/* webpackChunkName: 'batch_details' */ './batch_details'),
    },
    {
      path: '/receipts',
      load: () =>
        import(
          /* webpackChunkName: 'receipt_home' */ './accounts_management/receipts/receipts_home.js'
        ),
    },
    {
      path: '/receipts/receipts_entry',
      load: () =>
        import(
          /* webpackChunkName: 'receipts_entry' */ './accounts_management/receipts/receipts_entry.js'
        ),
    },
    {
      path: '/receipts/receipts_details',
      load: () =>
        import(
          /* webpackChunkName: 'receipts_details' */ './accounts_management/receipts/receipts_details.js'
        ),
    },
    {
      path: '/receipts/receipts_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'receipts_details_edit' */ './accounts_management/receipts/receipts_details_edit.js'
        ),
    },
    {
      path: '/cnf_receipts',
      load: () =>
        import(
          /* webpackChunkName: 'receipt_home' */ './accounts_management/receipts_cnf/receipts_home.js'
        ),
    },
    {
      path: '/cnf_receipts/receipts_entry',
      load: () =>
        import(
          /* webpackChunkName: 'receipts_entry' */ './accounts_management/receipts_cnf/receipts_entry.js'
        ),
    },
    {
      path: '/cnf_receipts/receipts_details',
      load: () =>
        import(
          /* webpackChunkName: 'receipts_details' */ './accounts_management/receipts_cnf/receipts_details.js'
        ),
    },
    {
      path: '/cnf_receipts/receipts_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'receipts_details_edit' */ './accounts_management/receipts_cnf/receipts_details_edit.js'
        ),
    },
    {
      path: '/credit_note_reconciliation',
      load: () =>
        import(
          /* webpackChunkName:'credit_note_reconciliation' */ './credit_note_reconciliation'
        ),
    },
    {
      path: '/cnf_sale_bills',
      load: () =>
        import(/* webpackChunkName:'cnf_sale_bills' */ './cnf_sale_bills'),
    },
    {
      path: '/payments',
      load: () =>
        import(
          /* webpackChunkName: 'payments_home' */ './accounts_management/payments/payments_home.js'
        ),
    },
    {
      path: '/payments/payments_entry',
      load: () =>
        import(
          /* webpackChunkName: 'receipts_entry' */ './accounts_management/payments/payments_entry.js'
        ),
    },
    {
      path: '/payments/payments_details',
      load: () =>
        import(
          /* webpackChunkName: 'receipts_details' */ './accounts_management/payments/payments_details.js'
        ),
    },
    {
      path: '/payments/payments_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'payments_details_edit' */ './accounts_management/payments/payments_details_edit.js'
        ),
    },
    {
      path: '/cheque_return',
      load: () =>
        import(
          /* webpackChunkName: 'receipt_vouchers' */ './accounts_management/cheque_returns/cheque_returns_home.js'
        ),
    },
    {
      path: '/receipt_vouchers',
      load: () =>
        import(
          /* webpackChunkName: 'receipt_vouchers' */ './accounts_management/vouchers/receipt_vouchers/home.js'
        ),
    },
    {
      path: '/vouchers/reciept_voucher_entry',
      load: () =>
        import(
          /* webpackChunkName: 'reciept_voucher_entry' */ './accounts_management/vouchers/receipt_vouchers/entry.js'
        ),
    },
    {
      path: '/vouchers/reciept_voucher_details',
      load: () =>
        import(
          /* webpackChunkName: 'reciept_voucher_details' */ './accounts_management/vouchers/receipt_vouchers/details.js'
        ),
    },
    {
      path: '/vouchers/reciept_voucher_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'reciept_voucher_details_edit' */ './accounts_management/vouchers/receipt_vouchers/details_edit.js'
        ),
    },
    {
      path: '/payment_vouchers',
      load: () =>
        import(
          /* webpackChunkName: 'payment_vouchers' */ './accounts_management/vouchers/payment_vouchers/home.js'
        ),
    },
    {
      path: '/vouchers/payment_voucher_entry',
      load: () =>
        import(
          /* webpackChunkName: 'payment_voucher_entry' */ './accounts_management/vouchers/payment_vouchers/entry.js'
        ),
    },
    {
      path: '/vouchers/payment_voucher_details',
      load: () =>
        import(
          /* webpackChunkName: 'payment_voucher_details' */ './accounts_management/vouchers/payment_vouchers/details.js'
        ),
    },
    {
      path: '/vouchers/payment_voucher_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'payment_voucher_details_edit' */ './accounts_management/vouchers/payment_vouchers/details_edit.js'
        ),
    },
    {
      path: '/journal_vouchers',
      load: () =>
        import(
          /* webpackChunkName: 'journal_vouchers' */ './accounts_management/vouchers/journal_vouchers/home.js'
        ),
    },
    {
      path: '/vouchers/journal_voucher_entry',
      load: () =>
        import(
          /* webpackChunkName: 'journal_voucher_entry' */ './accounts_management/vouchers/journal_vouchers/entry.js'
        ),
    },
    {
      path: '/vouchers/journal_voucher_details',
      load: () =>
        import(
          /* webpackChunkName: 'journal_voucher_details' */ './accounts_management/vouchers/journal_vouchers/details.js'
        ),
    },
    {
      path: '/vouchers/journal_voucher_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'journal_voucher_details_edit' */ './accounts_management/vouchers/journal_vouchers/details_edit.js'
        ),
    },
    {
      path: '/contra_vouchers',
      load: () =>
        import(
          /* webpackChunkName: 'contra_vouchers' */ './accounts_management/vouchers/contra_vouchers/home.js'
        ),
    },
    {
      path: '/vouchers/contra_voucher_entry',
      load: () =>
        import(
          /* webpackChunkName: 'contra_voucher_entry' */ './accounts_management/vouchers/contra_vouchers/entry.js'
        ),
    },
    {
      path: '/vouchers/contra_voucher_details',
      load: () =>
        import(
          /* webpackChunkName: 'contra_voucher_details' */ './accounts_management/vouchers/contra_vouchers/details.js'
        ),
    },
    {
      path: '/vouchers/contra_voucher_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'contra_voucher_details_edit' */ './accounts_management/vouchers/contra_vouchers/details_edit.js'
        ),
    },
    {
      path: '/debit_note_vouchers',
      load: () =>
        import(
          /* webpackChunkName: 'debit_note_vouchers' */ './accounts_management/vouchers/debit_note_vouchers/home.js'
        ),
    },
    {
      path: '/vouchers/debit_note_voucher_entry',
      load: () =>
        import(
          /* webpackChunkName: 'debit_note_voucher_entry' */ './accounts_management/vouchers/debit_note_vouchers/entry.js'
        ),
    },
    {
      path: '/vouchers/debit_note_voucher_details',
      load: () =>
        import(
          /* webpackChunkName: 'debit_note_voucher_details' */ './accounts_management/vouchers/debit_note_vouchers/details.js'
        ),
    },
    {
      path: '/vouchers/debit_note_voucher_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'debit_note_voucher_details_edit' */ './accounts_management/vouchers/debit_note_vouchers/details_edit.js'
        ),
    },
    {
      path: '/credit_note_vouchers',
      load: () =>
        import(
          /* webpackChunkName: 'credit_note_vouchers' */ './accounts_management/vouchers/credit_note_vouchers/home.js'
        ),
    },
    {
      path: '/vouchers/credit_note_voucher_entry',
      load: () =>
        import(
          /* webpackChunkName: 'credit_note_voucher_entry' */ './accounts_management/vouchers/credit_note_vouchers/entry.js'
        ),
    },
    {
      path: '/vouchers/credit_note_voucher_details',
      load: () =>
        import(
          /* webpackChunkName: 'credit_note_voucher_details' */ './accounts_management/vouchers/credit_note_vouchers/details.js'
        ),
    },
    {
      path: '/vouchers/credit_note_voucher_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'credit_note_voucher_details_edit' */ './accounts_management/vouchers/credit_note_vouchers/details_edit.js'
        ),
    },
    {
      path: '/general_purchase_vouchers',
      load: () =>
        import(
          /* webpackChunkName: 'general_purchase' */ './accounts_management/vouchers/general_purchase_vouchers/home.js'
        ),
    },
    {
      path: '/vouchers/general_purchase_entry',
      load: () =>
        import(
          /* webpackChunkName: 'general_purchase_entry' */ './accounts_management/vouchers/general_purchase_vouchers/entry.js'
        ),
    },
    {
      path: '/vouchers/general_purchase_details',
      load: () =>
        import(
          /* webpackChunkName: 'general_purchase_details' */ './accounts_management/vouchers/general_purchase_vouchers/details.js'
        ),
    },
    {
      path: '/vouchers/general_purchase_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'general_purchase_details_edit' */ './accounts_management/vouchers/general_purchase_vouchers/details_edit.js'
        ),
    },
    {
      path: '/bank_reconciliation',
      load: () =>
        import(
          /* webpackChunkName: 'bank_reconciliation' */ './bank_reconciliation'
        ),
    },
    {
      path: '/invoice',
      load: () => import(/* webpackChunkName: 'invoice' */ './invoice'),
    },
    {
      path: '/sales_report',
      load: () =>
        import(
          /* webpackChunkName: 'sales_report' */ './reports_management/sales_report'
        ),
    },
    {
      path: '/b2c_sales_report',
      load: () =>
        import(
          /* webpackChunkName: 'sales_report' */ './reports_management/sales_report_b2c'
        ),
    },
    {
      path: '/short_expiry_report',
      load: () =>
        import(
          /* webpackChunkName: 'customer_report' */ './reports_management/short_expiry_report'
        ),
    },
    {
      path: '/sales_gst_report',
      load: () =>
        import(
          /* webpackChunkName: 'sales_gst_report' */ './reports_management/sales_gst_report'
        ),
    },
    {
      path: '/sales_return_report',
      load: () =>
        import(
          /* webpackChunkName: 'sales_return_report' */ './reports_management/sales_return_report'
        ),
    },
    {
      path: '/tcs_summary',
      load: () =>
        import(
          /* webpackChunkName: 'tcs_summary' */ './reports_management/tcs_summary'
        ),
    },
    {
      path: '/product_profitability_report',
      load: () =>
        import(
          /* webpackChunkName: 'product_profitability_report' */ './reports_management/product_profitability_report'
        ),
    },
    {
      path: '/retail/company_sales_report',
      load: () =>
        import(
          /* webpackChunkName: 'retail_company_sales_report' */ './retail_routes/company_sales_report'
        ),
    },
    {
      path: '/product_sale_report',
      load: () =>
        import(
          /* webpackChunkName: 'product_sale_report' */ './reports_management/product_sale_report'
        ),
    },
    {
      path: '/retail/supplier_company_sale_report',
      load: () =>
        import(
          /* webpackChunkName: 'retail_supplier_company_sale_report' */ './retail_routes/supplier_company_sale_report'
        ),
    },
    {
      path: '/product_ledger',
      load: () =>
        import(
          /* webpackChunkName: 'product_ledger' */ './reports_management/product_ledger'
        ),
    },
    {
      path: '/tally_sale_report',
      load: () =>
        import(
          /* webpackChunkName: 'tally_sale_report' */ './reports_management/tally_sale_report'
        ),
    },
    {
      path: '/stock_sale_report',
      load: () =>
        import(
          /* webpackChunkName: 'stock_sale_report' */ './reports_management/stock_sale_report'
        ),
    },
    {
      path: '/stock_sale_detailed_report',
      load: () =>
        import(
          /* webpackChunkName: 'stock_sale__detailed_report' */ './reports_management/stock_sale_detailed_report'
        ),
    },
    {
      path: '/supplier_invoice_analysis',
      load: () =>
        import(
          /* webpackChunkName: 'supplier_invoice_analysis' */ './reports_management/payments_receipts_analysis_report/supplier_invoice_analysis'
        ),
    },
    {
      path: '/sales_dashboard',
      load: () =>
        import(
          /* webpackChunkName: 'sales_dashboard' */ './dashboards/sales_dashboard'
        ),
    },
    {
      path: '/sales_dashboard_customer',
      load: () =>
        import(
          /* webpackChunkName: 'sales_dashboard_customer' */ './dashboards/sales_dashboard_customer'
        ),
    },
    {
      path: '/sales_return_dashboard',
      load: () =>
        import(
          /* webpackChunkName: 'sales_return_dashboard' */ './dashboards/sales_return_dashboard'
        ),
    },
    {
      path: '/purchase_dashboard',
      load: () =>
        import(
          /* webpackChunkName: 'purchase_dashboard' */ './dashboards/purchase_dashboard'
        ),
    },
    {
      path: '/purchase_return_dashboard',
      load: () =>
        import(
          /* webpackChunkName: 'purchase_return_dashboard' */ './dashboards/purchase_return_dashboard'
        ),
    },
    {
      path: '/non_moving_dashboard',
      load: () =>
        import(
          /* webpackChunkName: 'non_moving_dashboard' */ './dashboards/non_moving_dashboard'
        ),
    },
    {
      path: '/short_expiry_dashboard',
      load: () =>
        import(
          /* webpackChunkName: 'short_expiry_dashboard' */ './dashboards/short_expiry_dashboard'
        ),
    },
    {
      path: '/profitability_dashboard',
      load: () =>
        import(
          /* webpackChunkName: 'profitability_dashboard' */ './dashboards/profitability_dashboard'
        ),
    },
    {
      path: '/settings',
      load: () =>
        import(/* webpackChunkName: 'invoice_settings' */ './settings'),
    },
    {
      path: '/user_management',
      load: () =>
        import(
          /* webpackChunkName: 'user_management' */ './user_security/user_management'
        ),
    },
    {
      path: '/role_management',
      load: () =>
        import(
          /* webpackChunkName: 'role_management' */ './user_security/role_management'
        ),
    },
    {
      path: '/invoice_bulk',
      load: () =>
        import(/* webpackChunkName: 'tools' */ './tools/bulk_email_invoice'),
    },
    {
      path: '/refill_reminders',
      load: () =>
        import(
          /* webpackChunkName: 'refill reminder' */ './tools/refill_reminder'
        ),
    },
    {
      path: '/barcode',
      load: () => import(/* webpackChunkName: 'barcode' */ './tools/barcode'),
    },
    {
      path: '/cash_counter',
      load: () =>
        import(
          /* webpackChunkName: 'cash_counter' */ './tools/cash_counter_management'
        ),
    },
    {
      path: '/scheme_verification',
      load: () =>
        import(
          /* webpackChunkName:'scheme_verification' */ './tools/scheme_verification'
        ),
    },
    {
      path: '/doctor_target',
      load: () =>
        import(/* webpackChunkName:'doctor_target' */ './tools/doctor_targets'),
    },
    {
      path: '/sale_reduction',
      load: () =>
        import(
          /* webpackChunkName:'sale_reduction' */ './tools/sale_reductions'
        ),
    },
    {
      path: '/daybook',
      load: () => import(/* webpackChunkName:'daybook' */ './tools/daybook'),
    },
    {
      path: '/batch_shuffling',
      load: () =>
        import(
          /* webpackChunkName:'batch_shuffling' */ './tools/batch_shuffling'
        ),
    },
    {
      path: '/eway_bill',
      load: () =>
        import(/* webpackChunkName:'eway_bill' */ './tools/eway_bill'),
    },
    {
      path: '/packing_slip',
      load: () =>
        import(/* webpackChunkName:'packing_slip' */ './tools/packing_slip'),
    },
    {
      path: '/bills_copy',
      load: () =>
        import(/* webpackChunkName:'bills_copy' */ './tools/bills_copy'),
    },
    {
      path: '/batch_holding',
      load: () =>
        import(/* webpackChunkName:'batch_holding' */ './tools/batch_holding'),
    },
    {
      path: '/receipts_report',
      load: () =>
        import(
          /* webpackChunkName: 'receipts_report' */ './reports_management/receipts_report'
        ),
    },
    {
      path: '/receipts_report_summary',
      load: () =>
        import(
          /* webpackChunkName: 'receipts_report_summary' */ './reports_management/receipts_report_summary'
        ),
    },
    {
      path: '/receipts_customer_report',
      load: () =>
        import(
          /* webpackChunkName: 'receipts_customer_report' */ './reports_management/receipts_report_summary/customer_drilldown'
        ),
    },
    {
      path: '/receipts_customer_detail',
      load: () =>
        import(
          /* webpackChunkName: 'receipts_customer_detail' */ './reports_management/receipts_report_summary/receipts_drilldown'
        ),
    },
    {
      path: '/payments_report',
      load: () =>
        import(
          /* webpackChunkName: 'payments_report' */ './reports_management/payments_report'
        ),
    },
    {
      path: '/payments_customer_report',
      load: () =>
        import(
          /* webpackChunkName: 'payments_customer_report' */ './reports_management/payments_report/customer_drilldown'
        ),
    },
    {
      path: '/payments_customer_detail',
      load: () =>
        import(
          /* webpackChunkName: 'payments_customer_detail' */ './reports_management/payments_report/bill_drilldown'
        ),
    },
    {
      path: '/customer_receipt_details',
      load: () =>
        import(
          /* webpackChunkName: 'customer_receipt_details' */ './reports_management/payments_receipts_analysis_report/receivables_analysis_report'
        ),
    },
    {
      path: '/supplier_payment_details',
      load: () =>
        import(
          /* webpackChunkName: 'supplier_payment_details' */ './reports_management/payments_receipts_analysis_report/payables_analysis_report'
        ),
    },
    {
      path: '/customer_invoices_details',
      load: () =>
        import(
          /* webpackChunkName: 'customer_invoices_details' */ './reports_management/payments_receipts_analysis_report/customer_invoices_analysis'
        ),
    },
    {
      path: '/supplier_invoices_details',
      load: () =>
        import(
          /* webpackChunkName: 'supplier_invoices_details' */ './reports_management/payments_receipts_analysis_report/supplier_invoice_analysis'
        ),
    },
    {
      path: '/trial_balance_report',
      load: () =>
        import(
          /* webpackChunkName: 'trial_balance_report' */ './reports_management/trial_balance_report'
        ),
    },

    ///////// ACCOUNTING REPORTS //////////////////////////////////
    {
      path: '/balance_sheet_report',
      load: () =>
        import(
          /* webpackChunkName: 'balance_sheet_report' */ './accounting_reports/balance_sheet_report'
        ),
    },
    {
      path: '/balance_sheet_detailed_report',
      load: () =>
        import(
          /* webpackChunkName: 'balance_sheet_detailed_report' */ './accounting_reports/balance_sheet_detailed_report'
        ),
    },
    {
      path: '/day_book_report',
      load: () =>
        import(
          /* webpackChunkName: 'day_book_report' */ './accounting_reports/day_book_report'
        ),
    },
    {
      path: ['/day_gst_report', '/retail/day_gst_report'],
      load: () =>
        import(
          /* webpackChunkName: 'day_gst_report' */ './accounting_reports/day_gst_report'
        ),
    },
    {
      path: '/gst_3b_report',
      load: () =>
        import(
          /* webpackChunkName: 'gst_3b_report' */ './accounting_reports/gst_3b_report'
        ),
    },
    {
      path: '/gstr1_report',
      load: () =>
        import(
          /* webpackChunkName: 'gstr1_report' */ './accounting_reports/gstr1_report'
        ),
    },
    {
      path: '/gstr_2b_verification',
      load: () =>
        import(
          /* webpackChunkName: 'gstr_2b_verification' */ './accounting_reports/gstr_2b_verification'
        ),
    },
    {
      path: '/ledger_wise_reports',
      load: () =>
        import(
          /* webpackChunkName: 'ledger_wise_reports' */ './accounting_reports/ledgers_wise_report'
        ),
    },
    {
      path: '/ledger_wise_detailed_report',
      load: () =>
        import(
          /* webpackChunkName: 'ledgers_wise_detailed_report' */ './accounting_reports/ledgers_wise_detailed_report'
        ),
    },
    {
      path: '/profit_loss_detailed_report',
      load: () =>
        import(
          /* webpackChunkName: 'profit_loss_detailed_report' */ './accounting_reports/profit_loss_detailed_report'
        ),
    },
    {
      path: '/profit_loss_report',
      load: () =>
        import(
          /* webpackChunkName: 'profit_loss_report' */ './accounting_reports/profit_loss_report'
        ),
    },
    ////////////////////////////////////////////////////////////////////////////////
    {
      path: '/trade_account_report',
      load: () =>
        import(
          /* webpackChunkName: 'trade_account_report' */ './reports_management/trade_account_report'
        ),
    },
    {
      path: '/retail/purchase_sales_report',
      load: () =>
        import(
          /* webpackChunkName: 'purchase_sales_report' */ './reports_management/purchase_sales_report'
        ),
    },
    {
      path: '/products/edit',
      load: () =>
        import(
          /* webpackChunkName: 'products_edit' */ './masters_management/products/edit'
        ),
    },
    {
      path: '/company/edit',
      load: () =>
        import(
          /* webpackChunkName: 'company_edit' */ './masters_management/company/edit'
        ),
    },
    {
      path: '/suppliers/edit',
      load: () =>
        import(
          /* webpackChunkName: 'suppliers_edit' */ './masters_management/suppliers/edit'
        ),
    },
    {
      path: '/division/edit',
      load: () =>
        import(
          /* webpackChunkName: 'division_edit' */ './masters_management/division/edit'
        ),
    },
    {
      path: '/hsn/edit',
      load: () =>
        import(
          /* webpackChunkName: 'hsn_edit' */ './masters_management/hsn/edit'
        ),
    },
    {
      path: '/patients/edit',
      load: () =>
        import(
          /* webpackChunkName: 'patients_edit' */ './masters_management/patients/edit'
        ),
    },
    {
      path: '/customers/edit',
      load: () =>
        import(
          /* webpackChunkName: 'customers_edit' */ './masters_management/customers/edit'
        ),
    },
    {
      path: '/doctors/edit',
      load: () =>
        import(
          /* webpackChunkName: 'doctors_edit' */ './masters_management/doctors/edit'
        ),
    },
    {
      path: '/disease_category/edit',
      load: () =>
        import(
          /* webpackChunkName: 'disease_category_edit' */ './masters_management/disease_category/edit'
        ),
    },
    {
      path: '/area/edit',
      load: () =>
        import(
          /* webpackChunkName: 'area_edit' */ './masters_management/areas/edit'
        ),
    },
    {
      path: '/reports/:slug',
      load: () => import(/* webpackChunkName: 'reports' */ './reports'),
    },
    {
      path: '/bulk_irn_update',
      load: () =>
        import(/* webpackChunkName: 'bulk_irn_update' */ './tools/bulk_irn'),
    },
    {
      path: '/pick_list',
      load: () =>
        import(/* webpackChunkName: 'pick_list' */ './tools/pick_list'),
    },

    //////////////////////////////////////// CHEQUE MANAGEMENT ////////////////////////////////////////////////////////////////////
    {
      path: '/cheques',
      load: () =>
        import(
          /* webpackChunkName: 'cheque_home' */ './cheque_management/home.js'
        ),
    },
    {
      path: '/cheques/cheques_entry',
      load: () =>
        import(
          /* webpackChunkName: 'cheque_entry' */ './cheque_management/entry.js'
        ),
    },
    {
      path: '/cheques/cheques_details',
      load: () =>
        import(
          /* webpackChunkName: 'cheque_details' */ './cheque_management/details.js'
        ),
    },
    {
      path: '/cheques/cheques_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'cheque_details_edit' */ './cheque_management/details_edit.js'
        ),
    },
    //////////////////////////////////////// CNF DISPATCH MANAGEMENT ////////////////////////////////////////////////////////////////////
    {
      path: '/cnf/dispatches',
      load: () =>
        import(
          /* webpackChunkName: 'dispatch_home' */ './cnf_dispatch_management/home.js'
        ),
    },
    {
      path: '/cnf/dispatches/dispatch_entry',
      load: () =>
        import(
          /* webpackChunkName: 'dispatch_entry' */ './cnf_dispatch_management/entry.js'
        ),
    },
    {
      path: '/cnf/dispatches/dispatch_details',
      load: () =>
        import(
          /* webpackChunkName: 'dispatch_details' */ './cnf_dispatch_management/details.js'
        ),
    },
    {
      path: '/cnf/dispatches/dispatch_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'dispatch_details_edit' */ './cnf_dispatch_management/details_edit.js'
        ),
    },
    {
      path: '/cnf/dispatches/imported_bills',
      load: () =>
        import(
          /* webpackChunkName: 'imported_bills' */ './cnf_dispatch_management/imported_bills.js'
        ),
    },
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    //////////////////////////////////////// CLAIM MANAGEMENT ////////////////////////////////////////////////////////////////////
    {
      path: '/claims',
      load: () =>
        import(
          /* webpackChunkName: 'claims_home' */ './claim_management/home.js'
        ),
    },
    {
      path: '/claims/claims_entry',
      load: () =>
        import(
          /* webpackChunkName: 'claims_entry' */ './claim_management/entry.js'
        ),
    },
    {
      path: '/claims/claims_details',
      load: () =>
        import(
          /* webpackChunkName: 'claims_details' */ './claim_management/details.js'
        ),
    },
    {
      path: '/claims/claims_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'claims_details_edit' */ './claim_management/details_edit.js'
        ),
    },

    //////////////////////////////////////// CLAIM SETTLEMENT ////////////////////////////////////////////////////////////////////
    {
      path: '/claim_settlement',
      load: () =>
        import(
          /* webpackChunkName: 'claims_home' */ './claim_settlement/home.js'
        ),
    },
    {
      path: '/claim_settlement/settlement_entry',
      load: () =>
        import(
          /* webpackChunkName: 'claims_entry' */ './claim_settlement/entry.js'
        ),
    },
    {
      path: '/claim_settlement/settlement_details',
      load: () =>
        import(
          /* webpackChunkName: 'claims_details' */ './claim_settlement/details.js'
        ),
    },
    {
      path: '/claim_settlement/settlement_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'claims_details_edit' */ './claim_settlement/details_edit.js'
        ),
    },

    //////////////////////////////////////// BANK DEPOSIT SLIP ////////////////////////////////////////////////////////////////////
    {
      path: '/bank_deposit',
      load: () =>
        import(
          /* webpackChunkName: 'bank_deposit_home' */ './bank_deposit/home.js'
        ),
    },
    {
      path: '/bank_deposit/bank_deposit_entry',
      load: () =>
        import(
          /* webpackChunkName: 'bank_deposit_entry' */ './bank_deposit/entry.js'
        ),
    },
    {
      path: '/bank_deposit/bank_deposit_details',
      load: () =>
        import(
          /* webpackChunkName: 'bank_deposit_details' */ './bank_deposit/details.js'
        ),
    },
    {
      path: '/bank_deposit/bank_deposit_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'bank_deposit_details_edit' */ './bank_deposit/details_edit.js'
        ),
    },
    /////////////////////////////////////////////Inward Cold Storage Cases/////////////////////////////////////////////////////////////////////////////////
    {
      path: '/inward_cold_storage',
      load: () =>
        import(
          /* webpackChunkName: 'inward_cold_storage_home' */ './inward_cold_storage/home.js'
        ),
    },
    {
      path: '/inward_cold_storage/inward_order_entry',
      load: () =>
        import(
          /* webpackChunkName: 'inward_cold_storage_entry' */ './inward_cold_storage/entry.js'
        ),
    },
    {
      path: '/inward_cold_storage/inward_order_details',
      load: () =>
        import(
          /* webpackChunkName: 'inward_cold_storage_details' */ './inward_cold_storage/details.js'
        ),
    },
    {
      path: '/inward_cold_storage/inward_order_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'inward_cold_storage_details_edit' */ './inward_cold_storage/details_edit.js'
        ),
    },

    /////////////////////////////////////////////Cnf Inward Order/////////////////////////////////////////////////////////////////////////////////

    {
      path: '/cnf_inward_order',
      load: () =>
        import(
          /* webpackChunkName: 'cnf_inward_order_home' */ './cnf_inward_order/home.js'
        ),
    },

    {
      path: '/cnf_inward_order/inward_order_entry',
      load: () =>
        import(
          /* webpackChunkName: 'inward_order_entrty' */ './cnf_inward_order/entry.js'
        ),
    },

    {
      path: '/cnf_inward_order/inward_order_details',
      load: () =>
        import(
          /* webpackChunkName: 'cnf_inward_order_details' */ './cnf_inward_order/details.js'
        ),
    },
    {
      path: '/cnf_inward_order/inward_order_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'cnf_inward_order_details_edit' */ './cnf_inward_order/details_edit.js'
        ),
    },
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////// ONCO PACKING MANAGEMENT ////////////////////////////////////////////////////////////////////
    {
      path: '/picking_details',
      load: () =>
        import(
          /* webpackChunkName: 'picking_details_home' */ './picking_details_management/home.js'
        ),
    },
    {
      path: '/picking_details/picking_details_entry',
      load: () =>
        import(
          /* webpackChunkName: 'picking_details_entry' */ './picking_details_management/entry.js'
        ),
    },
    {
      path: '/picking_details/picking_details_details',
      load: () =>
        import(
          /* webpackChunkName: 'picking_details_details' */ './picking_details_management/details.js'
        ),
    },
    {
      path: '/picking_details/picking_details_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'picking_details_details_edit' */ './picking_details_management/details_edit.js'
        ),
    },
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////// NORMAL ONCO PACKING MANAGEMENT ////////////////////////////////////////////////////////////////////
    {
      path: '/normal_picking_details',
      load: () =>
        import(
          /* webpackChunkName: 'normal_picking_details_home' */ './normal_picking_details_management/home.js'
        ),
    },
    {
      path: '/normal_picking_details/picking_details_entry',
      load: () =>
        import(
          /* webpackChunkName: 'normal_picking_details_entry' */ './normal_picking_details_management/entry.js'
        ),
    },
    {
      path: '/normal_picking_details/picking_details_details',
      load: () =>
        import(
          /* webpackChunkName: 'normal_picking_details_details' */ './normal_picking_details_management/details.js'
        ),
    },
    {
      path: '/normal_picking_details/picking_details_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'normal_picking_details_details_edit' */ './normal_picking_details_management/details_edit.js'
        ),
    },
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////CHEQUE RECONCILIATION////////////////////////////////////
    {
      path: '/cheques_reconciliation',
      load: () =>
        import(
          /* webpackChunkName:'cheque_reconciliation' */ './cheque_reconciliation'
        ),
    },
    //////////////////////////////////////////////////////////////////////////
    ///////////////////////////CHEQUE BOOK MANAGEMENT////////////////////////////////////
    {
      path: '/cheque_books',
      load: () =>
        import(
          /* webpackChunkName:'cheque_book_management' */ './cheque_book_management'
        ),
    },
    //////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////// CHEQUE MANAGEMENT ////////////////////////////////////////////////////////////////////
    {
      path: '/cheque_issues',
      load: () =>
        import(
          /* webpackChunkName: 'cheque_issues_home' */ './cheque_issue_management/home.js'
        ),
    },
    {
      path: '/cheque_issues/issue_entry',
      load: () =>
        import(
          /* webpackChunkName: 'cheque_issues_entry' */ './cheque_issue_management/entry.js'
        ),
    },
    {
      path: '/cheque_issues/issue_details',
      load: () =>
        import(
          /* webpackChunkName: 'cheque_issues_details' */ './cheque_issue_management/details.js'
        ),
    },
    {
      path: '/cheque_issues/issue_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'cheque_issues_details_edit' */ './cheque_issue_management/details_edit.js'
        ),
    },
    {
      path: '/approval_requests',
      load: () =>
        import(
          /* webpackChunkName: 'approval_requests' */ './tools/approval_requests/index'
        ),
    },
    ////PRODUCT OFFERS
    {
      path: '/product_offers',
      load: () =>
        import(
          /* webpackChunkName: 'product_offers_home' */ './product_offers/home.js'
        ),
    },
    {
      path: '/product_offers/run_offer_entry',
      load: () =>
        import(
          /* webpackChunkName: 'product_run_offers_entry' */ './product_offers/run_offer_entry.js'
        ),
    },
    {
      path: '/product_offers/offer_entry',
      load: () =>
        import(
          /* webpackChunkName: 'product_create_offers_entry' */ './product_offers/create_offer_entry.js'
        ),
    },
    {
      path: '/customer_outstanding_adjustment',
      load: () =>
        import(
          /* webpackChunkName: 'customer_outstanding_adjustment' */ './tools/outstanding/customer_outstanding/outstanding_home.js'
        ),
    },
    {
      path: '/customer_outstanding/outstanding_adjustment_entry',
      load: () =>
        import(
          /* webpackChunkName: 'customer_outstanding_adjustment_entry' */ './tools/outstanding/customer_outstanding/outstanding_entry.js'
        ),
    },
    {
      path: '/customer_outstanding/outstanding_adjustment_details',
      load: () =>
        import(
          /* webpackChunkName: 'customer_outstanding_adjustment_details' */ './tools/outstanding/customer_outstanding/outstanding_details.js'
        ),
    },
    {
      path: '/supplier_outstanding_adjustment',
      load: () =>
        import(
          /* webpackChunkName: 'supplier_outstanding_adjustment' */ './tools/outstanding/supplier_outstanding/outstanding_home.js'
        ),
    },
    {
      path: '/supplier_outstanding/outstanding_adjustment_entry',
      load: () =>
        import(
          /* webpackChunkName: 'supplier_outstanding_adjustment_entry' */ './tools/outstanding/supplier_outstanding/outstanding_entry.js'
        ),
    },
    {
      path: '/supplier_outstanding/outstanding_adjustment_details',
      load: () =>
        import(
          /* webpackChunkName: 'supplier_outstanding_adjustment_details' */ './tools/outstanding/supplier_outstanding/outstanding_details.js'
        ),
    },
    ///// DEPRECIATION MANAGEMENT
    {
      path: '/depreciation_management',
      load: () =>
        import(
          /* webpackChunkName: 'outstanding_adjustment_details' */ './tools/depreciation'
        ),
    },
    ///// DUNNING OF LETTER
    {
      path: '/dunning_of_letter',
      load: () =>
        import(
          /* webpackChunkName: 'dunning_of_letter' */ './tools/dunning_of_letter'
        ),
    },

    ///// LANDING COSTS
    {
      path: '/landing_costs',
      load: () =>
        import(
          /* webpackChunkName: 'landing_costs_home' */ './landing_costs_management/home.js'
        ),
    },
    {
      path: '/landing_costs/entry',
      load: () =>
        import(
          /* webpackChunkName: 'landing_costs_entry' */ './landing_costs_management/entry.js'
        ),
    },
    {
      path: '/landing_costs/details',
      load: () =>
        import(
          /* webpackChunkName: 'landing_costs_details' */ './landing_costs_management/details.js'
        ),
    },
    {
      path: '/landing_costs/details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'landing_costs_details_edit' */ './landing_costs_management/details_edit.js'
        ),
    },
    {
      path: '/approval_requests',
      load: () =>
        import(
          /* webpackChunkName: 'approval_requests' */ './tools/approval_requests/index'
        ),
    },
    {
      path: '/cash_handover',
      load: () =>
        import(
          /* webpackChunkName: 'cash_handover' */ './tools/cash_handover/index.js'
        ),
    },
    {
      path: '/product_merge',
      load: () =>
        import(
          /* webpackChunkName: 'product_merge' */ './tools/product_merge/index.js'
        ),
    },
    {
      path: '/counter_sales',
      load: () =>
        import(
          /* webpackChunkName: 'counter_sales_home' */ './inventory_management_retail/counter_sale/home.js'
        ),
    },
    {
      path: '/counter_sales/entry',
      load: () =>
        import(
          /* webpackChunkName: 'counter_sales_entry' */ './inventory_management_retail/counter_sale/entry.js'
        ),
    },

    // DISPATCH MANAGEMENT
    {
      path: '/dispatches',
      load: () =>
        import(
          /* webpackChunkName: 'dispatch_home' */ './dispatch_management/home.js'
        ),
    },
    {
      path: '/dispatches/dispatch_entry',
      load: () =>
        import(
          /* webpackChunkName: 'dispatch_entry' */ './dispatch_management/entry.js'
        ),
    },
    {
      path: '/dispatches/dispatch_details',
      load: () =>
        import(
          /* webpackChunkName: 'dispatch_details' */ './dispatch_management/details.js'
        ),
    },
    {
      path: '/dispatches/dispatch_details_edit',
      load: () =>
        import(
          /* webpackChunkName: 'dispatch_details_edit' */ './dispatch_management/details_edit.js'
        ),
    },

    // DISPATCH MANAGEMENT - RETURN TO ORIGIN
    {
      path: '/return_to_origin',
      load: () =>
        import(
          /* webpackChunkName: 'return_to_origin_home' */ './return_to_origin/'
        ),
    },
    // {
    //   path: '/return_to_origin/return_to_origin_details',
    //   load: () =>
    //     import(
    //       /* webpackChunkName: 'return_to_origin_details' */ './return_to_origin/details.js'
    //     ),
    // },

    // Wildcard routes, e.g. { path: '(.*)', ... } (must go last)
    {
      path: '(.*)',
      load: () => import(/* webpackChunkName: 'not-found' */ './404_page'),
    },
  ],

  async action({ next }) {
    // Execute each child route until one of them return the result
    const route = await next();

    // Provide default values for title, description etc.
    if (route != null) {
      route.title = `${route?.title ?? 'Untitled Page'}`;
      route.description = route.description ?? '';
    }
    return route;
  },
};

// The error page is available by permanent url for development mode
if (__DEV__) {
  routes.children.unshift({
    path: '/error',
    action: require('./error').default,
  });
}

export default routes;
